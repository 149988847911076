import React from 'react';
import { StaticQuery, graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import { decodeEntities } from '../utils/helpers';
import { PageHeader } from '../components/PageHeader';
import './sitemap.scss';

const Sitemap = ({ location }) => {
  return (
    <StaticQuery
      query={graphql`
        {
        wordpressWpSettings {
          title
          siteUrl
          wordpressUrl
          }
          allWordpressPage(
            filter: {
              slug: {}, 
              yoast: {
                meta_robots_noindex: {
                  ne: "1"
                }
              }, 
              status: {
                eq: "publish"
              }
            }, 
            sort: {
              fields: menu_order}
          ) {
            nodes {
              path
              title
              wordpress_parent
              wordpress_id
            }
          }
        }
      `}
      render={(data) => {
        const { allWordpressPage, wordpressWpSettings } = data;
        const { wordpressUrl, title: siteTitle } = wordpressWpSettings
        const parentPages = allWordpressPage.nodes.filter(page => page.wordpress_parent === 0);
        const sitemapItems = parentPages.map(item => {
          const children = allWordpressPage.nodes.filter(page => {
            //if (!page.parent_element) return null;
            return page.wordpress_parent === item.wordpress_id;
          });
          return children.length > 0 ? {...item, children} : item;
        });
        return (
          <Layout location={location} wordpressUrl={wordpressUrl}>
            <SEO title={`Sitemap | ${siteTitle}`} />
            <PageHeader headerTitle="Sitemap" headerSubTitle="" headerBackgroundImage={null} location={location} />
            <section className="sitemap">
              <div className="outer">
                <div className="wrap">
                    <nav>
                    {sitemapItems.map(item => {
                        return (
                        <div className="sitemap-item">
                            <Link to={item.path}>{decodeEntities(item.title)}</Link>
                            {item.children && (
                            <div className="sitemap-item-children">
                                {item.children.map(child => {
                                return (
                                    <div className="sitemap-item-child">
                                    <Link
                                        to={child.path}
                                    >
                                        {decodeEntities(child.title)}
                                    </Link>
                                    </div>
                                );
                                })}
                            </div>
                            )}
                        </div>
                        );
                    })}
                    </nav>
                </div>
              </div>
            </section>
          </Layout>
        );
      }}
    />
  );
}

export default Sitemap;